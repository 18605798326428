import React from 'react';

import {drawerWidth} from "../Sidebar/ResponsiveDrawer";

function Home({mobile}) {
    return (
        <div className="homeDiv"
             style={{height: '100vh', width: mobile ? '100vw' : `calc(100vw-${drawerWidth}px)`}}>
            <h2>Home</h2>
        </div>
    );
}

export default Home;