import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import {v4 as uuidv4} from 'uuid';
import { ethnicities, expectedDateFormats } from './MehrAddUtils'
import MehrAddSubmitButton from './MehrAddSubmitButton';

export default function MehrAddForm() {
    const [ageFemale, setAgeFemale] = React.useState('')
    const [ageMale, setAgeMale] = React.useState('')
    const [ethnicityFemale, setEthnicityFemale] = React.useState(ethnicities[0]["value"])
    const [ethnicityMale, setEthnicityMale] = React.useState(ethnicities[0]["value"])
    const [locationFemale, setLocationFemale] = React.useState('')
    const [locationMale, setLocationMale] = React.useState('')
    const [locationNikah, setLocationNikah] = React.useState('')
    const [mehr, setMehr] = React.useState('')
    const [dateNikah, setDateNikah] = React.useState('')
    const [salaryFemale, setSalaryFemale] = React.useState('')
    const [salaryMale, setSalaryMale] = React.useState('')

    const isAgeValid = (age) => Number(age) >= 18;
    const isLocationValid = (loc) => loc;
    const isMehrValid = (mehr) => mehr && !isNaN(Number(mehr));
    const isNikahDateValid =
        (dateNikah) => moment(dateNikah, expectedDateFormats, true).isValid();

    function isDataValid() {
        return isAgeValid(ageFemale)
            && isAgeValid(ageMale)
            && isLocationValid(locationFemale)
            && isLocationValid(locationMale)
            && isLocationValid(locationNikah)
            && isMehrValid(mehr)
            && isNikahDateValid(dateNikah);
    }

    const handleChange = (event, female) => {
        female ? setEthnicityFemale(event.target.value) : setEthnicityMale(event.target.value);
    };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="AgeFields">
        <TextField
          id="female-age"
          label="Female Age"
          value={ageFemale}
          onInput={ e => setAgeFemale(e.target.value) }
          required
          error={ !isAgeValid((ageFemale)) }
        />
        <TextField
          id="male-age"
          label="Male Age"
          value={ageMale}
          onInput={ e => setAgeMale(e.target.value) }
          required
          error={ !isAgeValid((ageMale)) }
        />
      </div>
      <div className="EthnicityFields">
        <TextField
          id="female-ethnicity"
          select
          label="Female Ethnicity"
          value={ethnicityFemale}
          onChange={ e => handleChange(e, true) }
        >
            {ethnicities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
        </ TextField>
        <TextField
          id="male-ethnicity"
          select
          label="Male Ethnicity"
          value={ethnicityMale}
          onChange={ e => handleChange(e, false) }
        >
            {ethnicities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
        </ TextField>
      </div>
      <div className="LocationFields">
        <TextField
          id="female-location"
          label="Female Location"
          value={locationFemale}
          onChange={ e => setLocationFemale(e.target.value) }
          error={ !isLocationValid((locationFemale)) }
          required
        />
        <TextField
          id="male-location"
          label="Male Location"
          value={locationMale}
          onInput={ e => setLocationMale(e.target.value) }
          error={ !isLocationValid((locationMale)) }
          required
        />
        <TextField
          id="nikah-location"
          label="Nikah Location"
          value={locationNikah}
          onInput={ e => setLocationNikah(e.target.value) }
          error={ !isLocationValid((locationNikah)) }
          required
        />
      </div>
      <div className="MehrFields">
        <TextField
          id="mehr"
          label="Mehr"
          value={mehr}
          onInput={ e => setMehr(e.target.value) }
          error={ !isMehrValid((mehr)) }
          required
        />
        <TextField
          id="nikah-date"
          label="Nikah Date"
          value={dateNikah}
          onInput={ e => setDateNikah(e.target.value) }
          error={ !isNikahDateValid((dateNikah)) }
          helperText={ !isNikahDateValid((dateNikah))
              ? "MM-DD-YYYY or MM/DD/YYYY format required" : ""
          }
        />
      </div>
      <div className="SalaryFields">
        <TextField
          id="female-salary"
          label="Female Salary"
          value={salaryFemale}
          onInput={ e => setSalaryFemale(e.target.value) }
        />
        <TextField
          id="male-salary"
          label="Male Salary"
          value={salaryMale}
          onInput={ e => setSalaryMale(e.target.value) }
        />
      </div>
      <MehrAddSubmitButton
        validData = {isDataValid()}
        formData = {{
            "EntryID": uuidv4(),
            "ageFemale": ageFemale,
            "ageMale": ageMale,
            "ethnicityFemale": ethnicityFemale,
            "ethnicityMale": ethnicityMale,
            "locationFemale": locationFemale,
            "locationMale": locationMale,
            "locationNikah": locationNikah,
            "mehr": mehr,
            "dateNikah": moment(dateNikah).format('MM-DD-YYYY'),
            "salaryFemale": salaryFemale,
            "salaryMale": salaryMale
        }}
      >
      </ MehrAddSubmitButton>

    </Box>
  );
}