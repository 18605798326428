export const ethnicities = [
  {
    value: 'Unspecified',
    label: 'Unspecified',
  },
  {
    value: 'Asian',
    label: 'Asian',
  },
  {
    value: 'Native',
    label: 'American Indian or Alaska Native',
  },
  {
    value: 'Black',
    label: 'Black or African American',
  },
  {
    value: 'Hispanic',
    label: 'Hispanic',
  },
  {
    value: 'White',
    label: 'White',
  },
  {
    value: 'Other',
    label: 'Multiracial or Other',
  },
];

export const expectedDateFormats = [
  "MM-DD-YYYY",
  "MM/DD/YYYY"
]

export async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return response.json();
}

export function validateMehrInputs() {

};