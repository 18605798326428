import axios from "axios";
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { drawerWidth } from '../Sidebar/ResponsiveDrawer';

import { columns, createData } from './MehrListUtils';

const rows = [];
function MehrList({mobile}) {
    console.log("mobile ", mobile)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [data, setData] = React.useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    React.useEffect(() => {
    console.log("in effect")
        setData([])
        axios
         .get("https://b3qf713t7a.execute-api.us-west-1.amazonaws.com/development/mehr-list")
         .then((res) => {
         console.log(res)
           setData(res.data.Items);
           console.log("Result:", res.data.Items);
           res.data.Items.forEach(row => (console.log(`foreach: ${createData(row)} len ${rows.length}`)));
           res.data.Items.forEach(row => rows.push(createData(row)));

           console.log("Rows:", rows)
         })
         .catch((error) => {
           console.log(error);
         });
    }, []);

    return (
    <Paper sx={{ width: mobile ? "100%" : `calc(100% - ${drawerWidth}px)`, overflow: 'hidden', float: 'right' }}>
        <h2>Mehr List WIP</h2>
      <TableContainer style={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.toString()}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    );
}

export default MehrList;