import React from 'react';
import Paper from '@mui/material/Paper';
import MehrAddForm from './MehrAddForm';

import { drawerWidth } from '../Sidebar/ResponsiveDrawer';

function MehrAdd({mobile}) {
    console.log("mobile ", mobile)
    return (
    <Paper sx={{ width: mobile ? "100%" : `calc(100% - ${drawerWidth}px)`, overflow: 'hidden', float: 'right' }}>
        <h2>Add Mehr WIP</h2>
        <MehrAddForm />
    </Paper>
    );
}

export default MehrAdd;