import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListIcon from '@mui/icons-material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const drawerWidth = 240;

function textToUrlMapping(text) {
    switch(text) {
        case "Home":
            return "/"
        case "Add Mehr":
            return "/mehr-add"
        case "Mehr List":
            return "/mehr-list"
        case "Booking":
            return "/book"
        default:
            return "/"
    }
}

function Sidenav() {
    return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {['Home', 'Add Mehr', 'Mehr List', 'Booking'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton href={`${textToUrlMapping(text)}`}>
              <ListItemIcon>
                {
                index === 0 ? <HomeIcon /> :
                index === 1 ? <AddCircleIcon /> :
                index === 2 ? <ListIcon /> :
                index === 3 ? <BookOnlineIcon />:
                null
                }
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['About', 'Donate', 'Contact'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {
                index === 0 ? <InfoIcon /> :
                index === 1 ? <VolunteerActivismIcon /> :
                index === 2 ? <ContactSupportIcon /> :
                null
                }
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default Sidenav;