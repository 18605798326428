export const columns = [
  { id: 'age_f', label: 'Female Age', minWidth: 10 },
  { id: 'age_m', label: 'Male Age', minWidth: 10 },
  { id: 'eth_f', label: 'Female Ethnicity', minWidth: 100 },
  { id: 'eth_m', label: 'Male Ethnicity', minWidth: 100 },
  { id: 'loc_f', label: 'Female Location', minWidth: 10 },
  { id: 'loc_m', label: 'Male Location', minWidth: 10 },
  { id: 'loc_nikah', label: 'Nikah Location', minWidth: 10 },
  { id: 'mehr', label: 'Mehr', minWidth: 10 },
  { id: 'date_nikah', label: 'Nikah Date', minWidth: 10 },
  {
    id: 'salary_f',
    label: 'Female Salary',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'salary_m',
    label: 'Male Salary',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
];

export function createData(row) {
    console.log("row", row)
    let age_f = row['ageFemale']['S']
    let age_m = row['ageMale']['S']
    let eth_f = row['ethnicityFemale']['S']
    let eth_m = row['ethnicityMale']['S']
    let loc_f = row['locationFemale']['S']
    let loc_m = row['locationMale']['S']
    let loc_nikah = row['locationNikah']['S']
    let mehr = row['mehr']['S']
    let date_nikah = row['dateNikah']['S']
    let salary_f = row['salaryFemale']['S']
    let salary_m = row['salaryMale']['S']
  return {age_f, age_m, eth_f, eth_m, loc_f, loc_m, loc_nikah, mehr, date_nikah, salary_f, salary_m};
}