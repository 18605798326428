import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';

import Booking from './components/Booking/Booking';
import Home from './components/Home/Home';
import MehrAdd from './components/MehrAdd/MehrAdd';
import MehrList from './components/MehrList/MehrList';
import ResponsiveDrawer from './components/Sidebar/ResponsiveDrawer'

function App() {
    const [isMobile, setIsMobile] = React.useState(false)

    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // create an event listener
    React.useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    })
  return (
    <div className="App" style={{height: '100vh', width: '100vw'}}>
      <BrowserRouter>
      <ResponsiveDrawer isMobile={isMobile}/>
        <Routes>
            <Route path='/' element={<Home mobile={isMobile}/>} />
            <Route path='/mehr-add' element={<MehrAdd mobile={isMobile}/> } />
            <Route path='/mehr-list' element={<MehrList mobile={isMobile}/>} />
            <Route path='/book' element={<Booking/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
