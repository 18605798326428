import * as React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

import { postData } from './MehrAddUtils';

export default function MehrAddSubmitButton(props) {
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false);

    function submissionAlert(succeeded) {
        if (succeeded) {
            setOpenSuccessAlert(true);
            setOpenErrorAlert(false);
        } else {
            setOpenSuccessAlert(false);
            setOpenErrorAlert(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        console.log("IN HANDLE SUBMIT", event);
        console.log(props);
        if (props.validData) {
            postData('https://b3qf713t7a.execute-api.us-west-1.amazonaws.com/development/mehr-add',
                props.formData
            ).then((data) => {
                console.log(data);
                data["MehrAddResult"] === "Success"
                    ? submissionAlert(true)
                    : submissionAlert(false);
            });
        } else {
            console.log("DATA NOT VALID");
        }
    }
  return (
  <Box>
      <Button
          variant="contained"
          endIcon={<SendIcon />}
          sx={{mt: 5, mb: 5}}
          type="submit"
          onClick={handleSubmit}
          disabled={!props.validData}
      >
          Send
      </Button>
      <Collapse in={openSuccessAlert}>
          <Alert
              action={
                  <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                          setOpenSuccessAlert(false);
                      }}
                  >
                      <CloseIcon fontSize="inherit" />
                  </IconButton>
              }
              sx={{ mb: 2 }}
          >
              Submitted successfully!
          </Alert>
      </Collapse>
      <Collapse in={openErrorAlert}>
          <Alert
              action={
                  <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                          setOpenErrorAlert(false);
                      }}
                  >
                      <CloseIcon fontSize="inherit" />
                  </IconButton>
              }
              sx={{ mb: 2 }}
              severity={"error"}
          >
              Error occurred during submission!
          </Alert>
      </Collapse>
  </Box>
  );
}